import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import Link from "../Link";

class FramedBanner extends React.Component {

    render(){

        return (
            <div className="banner framed-content" style={{backgroundImage: "url(" + this.props.bgImage + ")"}}>
                <Row type="flex" className="content" gutter={70}>
                    <Col xs={{span: 24}} md={{span: 8}} lg={{span: 12}}>
                        <div className="frame-con-top">
                            {this.props.topCon}
                        </div>
                        <div className="frame-con-bottom">
                            {this.props.bottomCon}
                        </div>
                        <Link className={"btn btn-" + this.props.btnColor} to={this.props.btnLink}>
                            <span>{this.props.btnText}</span>
                            <span className="icon arrow"></span>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default FramedBanner