import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import CatListingItem from "../CatListingItem";

class SubCatListing extends React.Component {
    render() {
        let pre = "/";

        if(this.props.locale === "cy") {
            pre = "/cy/";
        }

        return (
            <div className="sub-cat-listing">
                <Row type="flex" gutter={70}>
                    <Col xs={{span: 24}}>
                        <h2>{this.props.title}</h2>
                        <div className="cat-listing">
                            {this.props.cats && this.props.cats.map(({node}) => {
                                if(node.translations.current === this.props.locale && node.parent_element.slug === this.props.parent) {
                                    let img = "https://via.placeholder.com/640";
                                    if (node.acf.listing_image !== null) {
                                        img = node.acf.listing_image.localFile.childImageSharp.fluid;
                                    }
                                    return <CatListingItem
                                        key={node.id}
                                        urlPath={pre + node.parent_element.slug + "/" + node.slug + "/"}
                                        color={node.parent_element.acf.listing_colour}
                                        // options: primary, secondary, orange, teal, yellow
                                        image={img}
                                        // Will need to be a fluid gatsby image of 640x640
                                        title={node.title}
                                        blurb={node.acf.listing_blurb}
                                    />
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SubCatListing
